/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import List from '@mui/material/List';
import { Ancestry, AncestryGroup, AncestryRegion } from '../../../../../../core/api/portal.types';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { getColors, regionColors, getRegionIndex, useStyles } from '../../component.styles';
import { theme } from '@omnigenbiodata/ui';
import Collapse from '@mui/material/Collapse';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export interface AncestryListProps {
  ancestryData: Ancestry;
}

function AncestryList({ ancestryData }: AncestryListProps) {
  const classes = useStyles();
  const [groupedData, setGroupedData] = useState<AncestryRegion[]>([]);
  const [openTooltip, setOpenTooltip] = useState<string | null>(null);

  const handleTooltipToggle = (id: string) => {
    setOpenTooltip((prev) => (prev === id ? null : id));
  };

  const handleTooltipClose = () => {
    setOpenTooltip(null);
  };

  useEffect(() => {
    if (ancestryData.status === 'complete') {
      const regions = ancestryData.regions
        .filter(({ percentage }) => percentage > 0)
        .map((region: AncestryRegion) => {
          const groups = region.groups
            .filter(({ percentage }) => percentage > 0)
            .sort((a, b) => b.percentage - a.percentage);

          return {
            ...region,
            groups,
          };
        })
        .sort((a, b) => b.percentage - a.percentage);
      setGroupedData(regions);
    }
  }, [ancestryData]);

  const [open] = useState(true);

  return groupedData ? (
    <List sx={{ ml: -1 }} onClick={handleTooltipClose}>
      {groupedData.map((region: AncestryRegion, regionIndex: number) => (
        <div key={region.geneticRegion} data-testid={region.geneticRegion}>
          <ListItem
            sx={{
              bgcolor: theme.palette.grey[300],
              color: theme.palette.text.primary,
            }}
            css={classes.ancestry}
            secondaryAction={
              <ListItemText
                primary={region.percentage?.toLocaleString(undefined, {
                  style: 'percent',
                  minimumFractionDigits: 1,
                })}
                sx={{ textAlign: 'end', pr: 1 }}
              />
            }
          >
            <svg width={16} height={16} fill={regionColors[getRegionIndex(region.geneticRegion)]}>
              <circle cx={8} cy={8} r={8} />
            </svg>
            <ListItemText sx={{ pl: 2 }} primary={region.geneticRegionLabel} />
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List sx={{ bgcolor: theme.palette.grey[100], color: theme.palette.primary.main }}>
              {region.groups.map(({ group, label, percentage, description }: AncestryGroup, groupIndex: number) => (
                <ListItem
                  sx={{ mx: 1 }}
                  key={group}
                  data-testid={group}
                  css={classes.ancestry}
                  secondaryAction={
                    <ListItemText
                      primary={Number(percentage).toLocaleString(undefined, {
                        style: 'percent',
                        minimumFractionDigits: 1,
                      })}
                      sx={{ textAlign: 'end', mt: -0.5, mr: 2 }}
                    />
                  }
                >
                  <ListItemText sx={{ pl: 2, mr: 3 }} css={classes.ancestryListItemText}>
                    <Grid container spacing={2} alignItems="left">
                      <Grid item xs={1}>
                        <svg
                          width={16}
                          height={16}
                          fill={getColors(groupIndex, region.groups.length, null, region.geneticRegion)}
                        >
                          <circle cx={8} cy={8} r={8} />
                        </svg>
                      </Grid>
                      <Grid item xs="auto">
                        <Typography textAlign="center" component="p" paragraph sx={{ mt: -0.3, mb: 0 }}>
                          {label}
                        </Typography>
                      </Grid>
                      <Grid item xs="auto">
                        {description !== null && (
                          <Tooltip
                            key={`tooltip-${regionIndex}-${groupIndex}`}
                            title={description}
                            sx={{ mt: -0.3 }}
                            open={openTooltip === `${regionIndex}-${groupIndex}`}
                            onClose={handleTooltipClose}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            arrow
                          >
                            <span data-testid={`tooltip-icon-${regionIndex}-${groupIndex}`}>
                              <HelpCenterIcon
                                onClick={() => handleTooltipToggle(`${regionIndex}-${groupIndex}`)}
                                onMouseOver={(e) => {
                                  e.preventDefault();
                                  handleTooltipToggle(`${regionIndex}-${groupIndex}`);
                                }}
                                onMouseOut={(e) => {
                                  e.preventDefault();
                                  handleTooltipClose();
                                }}
                                onTouchEnd={(e) => {
                                  e.preventDefault();
                                  handleTooltipToggle(`${regionIndex}-${groupIndex}`);
                                }}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid item xs="auto"></Grid>
                    </Grid>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
      ))}
    </List>
  ) : null;
}

export default AncestryList;
