/** @jsxImportSource @emotion/react */
import { useRef, useEffect } from 'react';
import { select } from 'd3';
import { useStyles } from '../../../component.styles';
import Box from '@mui/material/Box';
import { drawGroups, drawWorldMap } from '../../../utils/map.utils';
import { useTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { theme } from '@omnigenbiodata/ui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ANCESTRY_REGIONS_LIST } from '../../../../../../../core/constants/content.constants';

export interface AncestryMapProps {
  ancestryData: AncestryLocation[];
  ancestryTableData: AncestryTableDataItem[];
  title: string;
}

interface AncestryLocation {
  lat: number;
  lng: number;
  group: string;
  regionIndex: number;
  groupIndex: number;
  groupLength: number;
  geneticRegion: string;
}

interface AncestryTableDataItem {
  group: string;
  groupLabel: string;
  geneticRegion: string;
  regionIndex: number;
  groupDescription: string;
}

function AncestryMap({ ancestryData, ancestryTableData }: AncestryMapProps) {
  const { t } = useTranslation('portal');
  const ref = useRef(null as unknown as SVGSVGElement);
  const classes = useStyles();

  useEffect(() => {
    if (ref.current) {
      const svg = select<SVGSVGElement, null>(ref.current);
      const map = svg.append('g');

      map.call(drawWorldMap);
      drawGroups(ancestryData, map);
    }
  }, [ancestryData]);

  const getRegionTranslationKey = (geneticRegion: string): string => {
    const regionKey = `ancestry.regionNames.${geneticRegion}`;
    return regionKey;
  };

  return (
    <>
      <p>{t('ancestry.tabs.allGroupsMap.descriptionParagraph1')}</p>
      <p>{t('ancestry.tabs.allGroupsMap.descriptionParagraph2')}</p>
      <p>{t('ancestry.tabs.allGroupsMap.descriptionParagraph3')}</p>

      <Box css={classes.ancestry} data-testid="ancestry-map">
        <svg width="100%" height="100%" viewBox="50 -50 900 500" preserveAspectRatio="xMaxYMax meet" ref={ref} />
        <div>
          {ANCESTRY_REGIONS_LIST.map((region, index) => (
            <>
              <Accordion key={`accordion_region_${region}`} data-testid={`accordion_region_${region}`}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <strong>{t(getRegionTranslationKey(region))}</strong>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper} elevation={0}>
                    <Table
                      sx={{ minWidth: 650, borderRadius: 10 }}
                      aria-label="Ancestry data table"
                      data-testid={`region_${region}_desc_table`}
                    >
                      <TableBody>
                        {ancestryTableData
                          .filter((data) => data.geneticRegion === region)
                          .map((row) => (
                            <TableRow
                              data-testid={`region_group_${row.group}_row`}
                              key={`region_group_${row.group}_row`}
                              sx={{ background: theme.palette.grey[50], cursor: 'pointer' }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                data-testid={`region_group_${row.group}_label_col`}
                                sx={{ border: `1px solid ${theme.palette.grey[200]}` }}
                              >
                                <strong>{row.groupLabel}</strong>
                              </TableCell>
                              <TableCell sx={{ border: `1px solid ${theme.palette.grey[200]}` }}>
                                {row.groupDescription}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </>
          ))}
        </div>
      </Box>
    </>
  );
}

export default AncestryMap;
