/** @jsxImportSource @emotion/react */
import { Ancestry, AncestryGroup, AncestryRegion } from '../../../../../../core/api/portal.types';
import { Checkbox, FormControlLabel, FormGroup, Paper } from '@mui/material';
import { ButtonRow, FormSelect, useTrackedFormik } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useStyles } from '../../component.styles';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { setAncestryFeedbackThunk } from '../../../../../../store/ancestry';
import { useAppDispatch } from '../../../../../../store';

export interface AncestryFeedbackProps {
  ancestryData: Ancestry;
  setDisplayThankYouMsg: any;
}

export interface MyFormValues {
  matchesExpectation: string;
  selectedRegionGroups: string[];
}

function AncestryFeedback({ ancestryData, setDisplayThankYouMsg }: AncestryFeedbackProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('portal');
  const [groupedData, setGroupedData] = useState<AncestryRegion[]>([]);

  const matchesExpectationOptions = [
    {
      value: 'YES',
      label: t('ancestry.howYouCanHelpSection.matchesExpectations.yesOption') || '',
    },
    {
      value: 'NO',
      label: t('ancestry.howYouCanHelpSection.matchesExpectations.noOption') || '',
    },
  ];
  const initialValues: MyFormValues = {
    matchesExpectation: 'YES',
    selectedRegionGroups: [],
  };

  const matchesExpectationErrorMsg = t('ancestry.howYouCanHelpSection.matchesExpectations.matchesExpectationError');
  const selectedRegionGroupsError = t('ancestry.howYouCanHelpSection.matchesExpectations.selectedRegionGroupsError');
  const validationSchema = yup.object().shape({
    matchesExpectation: yup.string().min(1, matchesExpectationErrorMsg),
    selectedRegionGroups: yup.array().when('matchesExpectation', {
      is: 'NO',
      then: yup.array().min(1, selectedRegionGroupsError),
      otherwise: yup.array().notRequired(),
    }),
  });

  const formik = useTrackedFormik(
    {
      initialValues: initialValues,
      validationSchema,
      validateOnChange: true,
      onSubmit: (values: any) => {
        dispatch(
          setAncestryFeedbackThunk({
            expectedGroups: values.matchesExpectation === 'YES' ? [] : values.selectedRegionGroups,
            matchesExpectations: values.matchesExpectation === 'YES' ? true : false,
          }),
        );
        setDisplayThankYouMsg(true);
      },
    },
    'ancestry-matches-expectations',
  );

  const getTranslationKeyGeneticRegion = (geneticRegion: string) => {
    return `ancestry.regionNames.${geneticRegion.toLowerCase()}`;
  };

  useEffect(() => {
    if (ancestryData.status === 'complete') {
      setGroupedData(ancestryData.regions);
    }
  }, [ancestryData]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Paper css={classes.ancestryHowCanYouHelpBox}>
        <Typography variant="h5" component="h1" paragraph>
          {t('ancestry.howYouCanHelpSection.title')}
        </Typography>
        <p>{t('ancestry.howYouCanHelpSection.descriptionParagraph1')}</p>
        <ul>
          <li>
            <strong>{t('ancestry.howYouCanHelpSection.bulletPointText1Bold')}</strong>
            {t('ancestry.howYouCanHelpSection.bulletPointText1')}
          </li>
          <li>
            <strong>{t('ancestry.howYouCanHelpSection.bulletPointText2Bold')}</strong>
            {t('ancestry.howYouCanHelpSection.bulletPointText2')}
          </li>
        </ul>

        <FormSelect
          placeholder=""
          error={formik.errors.matchesExpectation as string}
          name="matchesExpectation"
          label={t('ancestry.howYouCanHelpSection.matchesExpectations.label')}
          onChange={(e: any) => {
            formik.handleChange(e);
            formik.setFieldValue('matchesExpectation', e.currentTarget.value);
          }}
          onBlur={formik.handleBlur}
          touched={formik.touched.matchesExpectation as boolean}
          value={formik.values.matchesExpectation}
          options={matchesExpectationOptions}
        />

        {formik.values.matchesExpectation === 'NO' && (
          <>
            <Typography component="p" sx={{ marginTop: '10px', fontWeight: 'bold' }}>
              {t('ancestry.howYouCanHelpSection.matchesExpectations.selectExpectedGroups')}
            </Typography>

            {formik.touched.selectedRegionGroups && formik.errors.selectedRegionGroups && (
              <p css={classes.ancestryFormErrorMsg}>{formik.errors.selectedRegionGroups as string}</p>
            )}

            <FormGroup row>
              {groupedData.map((region: AncestryRegion, index: number) => (
                <>
                  <div
                    style={{ width: '100%', marginTop: '12px' }}
                    key={`region_div_${region.geneticRegion.toUpperCase()}_${index}`}
                  >
                    <Typography
                      key={`region_section_${region.geneticRegion.toUpperCase()}_${index}`}
                      variant="h6"
                      component="h1"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {t(getTranslationKeyGeneticRegion(region.geneticRegion))}
                    </Typography>
                  </div>
                  {region.groups.map(({ group, label }: AncestryGroup) => (
                    <FormControlLabel
                      key={`${region.geneticRegion.toUpperCase()}_group_${group}_${index}`}
                      control={
                        <Checkbox
                          key={`check_${region.geneticRegion.toUpperCase()}_group_${group}_${index}`}
                          value={group.toUpperCase()}
                          checked={formik.values.selectedRegionGroups.includes(group.toString().toUpperCase())}
                          onChange={() => {
                            if (formik.values.selectedRegionGroups.includes(group.toString().toUpperCase())) {
                              // Uncheck
                              formik.setFieldValue(
                                'selectedRegionGroups',
                                formik.values.selectedRegionGroups.filter(
                                  (id: any) => id !== group.toString().toUpperCase(),
                                ),
                              );
                            } else {
                              // Check
                              formik.setFieldValue('selectedRegionGroups', [
                                ...formik.values.selectedRegionGroups,
                                group.toString().toUpperCase(),
                              ]);
                            }
                          }}
                        />
                      }
                      label={label}
                    />
                  ))}
                </>
              ))}
            </FormGroup>
          </>
        )}
        <ButtonRow
          isDisabled={formik.values.matchesExpectation === 'NO' && formik.values.selectedRegionGroups.length === 0}
          onForward={formik.submitForm}
          showBack={false}
          forwardLabel={t('ancestry.howYouCanHelpSection.SubmitButtonText') || ''}
          forwardColor="primary"
          buttonSize="medium"
        />
      </Paper>
    </form>
  );
}

export default AncestryFeedback;
